import React, { useCallback, useEffect, useState } from "react";
import {
  BannerPage,
  ContainerContentFixed,
  Layout,
  SEO,
  ContainerCta,
  InternalLinkTypes,
  ContainerContent,
  StoreyFilter,
  RangeFilter,
  ContentDisclaimer,
  DisplayFacadesList,
} from "../components";
import { graphql, useStaticQuery } from "gatsby";
import { Row, Col, Space } from "antd";

import { Colors } from "../components/";

const Facades = () => {
  const data = useStaticQuery(graphql`
    query {
      allFacadesJson {
        nodes {
          slug
          name
          storey
          range
          imagePath {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const facades = data.allFacadesJson.nodes;
  const [filteredPlans, setFilteredPlans] = useState([]);
  const [selectedStorey, setSelectedStorey] = useState("all");

  const onChangeSelectedStorey = useCallback(
    value => {
      setSelectedStorey(value);
    },
    [setSelectedStorey]
  );

  const [selectedRange, setSelectedRange] = useState("all");

  const onChangeSelectedRange = useCallback(
    value => {
      setSelectedRange(value);
    },
    [setSelectedRange]
  );

  useEffect(() => {
    let filtered = [];
    facades.forEach(plan => {
      if (
        (selectedStorey.toString() === plan.storey.toString() ||
          selectedStorey.toString() === "all") &&
        (selectedRange.toString() === plan.range.toString() ||
          selectedRange.toString() === "all")
      )
        filtered.push(plan);
    });

    setFilteredPlans(filtered);
  }, [facades, selectedStorey, selectedRange]);

  return (
    <div className={"cp-facades"}>
      <Layout>
        <SEO
          title="Facades"
          description={"Browse through our existing facades"}
        />
        <BannerPage
          title={"Facades"}
          subTitle={"Browse through our existing facades"}
        />

        <ContainerContent
          style={{
            backgroundColor: Colors.LightGray,
            textAlign: "left",
            paddingTop: 0,
          }}
        >
          <ContainerContentFixed>
            <Space direction="vertical" size={16}>
              <Row gutter={[16, 16]}>
                <Col>
                  <RangeFilter
                    selectedFilter={selectedRange}
                    onChangeFilter={onChangeSelectedRange}
                  />
                </Col>
                <Col>
                  <StoreyFilter
                    selectedFilter={selectedStorey}
                    onChangeFilter={onChangeSelectedStorey}
                  />
                </Col>
              </Row>
              <DisplayFacadesList facades={filteredPlans} />

              <section style={{ textAlign: "center" }}>
                <ContentDisclaimer />
              </section>
            </Space>
          </ContainerContentFixed>
        </ContainerContent>
        <ContainerCta
          title={"Can't find the facades you like?"}
          subTitle={"Don't worry, we can build one as per your needs"}
          primaryText={"Get Started"}
          primaryLink={InternalLinkTypes.GetStarted}
          secondaryLink={InternalLinkTypes.HomeDesigns}
          secondaryText={"See Home Designs"}
        />
      </Layout>
    </div>
  );
};

export default Facades;
